import React from 'react';

export const SendMessageIcon = () => (
  <svg width="24" height="24" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line prettier/prettier */}
    <path
      d="M11.3906 13.0002H4.00056L1.52931 3.16899C1.51348 3.11186 1.50385 3.05319 1.50056 2.99399C1.47306 2.09274 2.46556 1.46774 3.32556 1.88024L26.5006 13.0002L3.32556 24.1202C2.47556 24.529 1.49556 23.9215 1.50056 23.0365C1.50309 22.9574 1.51698 22.8791 1.54181 22.804L3.37556 16.7502"
      stroke="white"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
