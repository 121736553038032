import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import {
  API_CUSTOM_REPORT_DATA_GET_URL,
  API_CUSTOM_REPORT_DATA_SET_URL,
  API_CUSTOM_REPORT_DATA_DELETE_URL,
  API_CUSTOM_REPORT_QUESTION_NEW_URL,
  API_CUSTOM_REPORT_TOPIC_NEW_URL,
} from './urls';

export const getReportDataByPage = async (params) =>
  axiosClient.get(API_CUSTOM_REPORT_DATA_GET_URL, axiosSSOConfigPrivate({}, { params: params }));

export const setReportData = async (params) =>
  axiosClient.post(API_CUSTOM_REPORT_DATA_SET_URL, params, axiosSSOConfigPrivate());

export const deleteReportData = async (params) =>
  axiosClient.post(API_CUSTOM_REPORT_DATA_DELETE_URL, params, axiosSSOConfigPrivate());

export const newQuestion = async (params) =>
  axiosClient.post(API_CUSTOM_REPORT_QUESTION_NEW_URL, params, axiosSSOConfigPrivate());

export const newTopic = async (params) =>
  axiosClient.post(API_CUSTOM_REPORT_TOPIC_NEW_URL, params, axiosSSOConfigPrivate());
