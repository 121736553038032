import { useLocation, useParams } from 'react-router-dom';
import { ExternalUrl } from '../../constants';

const ExternalLink = () => {
  const location = useLocation();
  const { param } = useParams();
  const external = ExternalUrl.find((el) => location.pathname.includes(el.url));
  if (external) {
    window.location.replace(`${external.externalUrl}${param ?? ''}`);
  }
  return null;
};

export default ExternalLink;
