import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { UserContext } from '../../context';

const LoadedDraftRoute = () => {
  const { draftId } = useContext(UserContext);
  if (!draftId) return <></>;
  return <Outlet />;
};

export default LoadedDraftRoute;
