import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@mui/material/styles';
import DisconnectHandlerWrapper from './utils/disconnectHandler/disconnectHandler';

import { AppState, ErrorState, CalculatorsState, UserState, ApiMethodService } from './context';
import appTheme from './mui-theme';
import apolloClient from './api/apollo/apollo-client';

import App from './App';
import './index.css';
import 'tt-ui-kit/dist/index.css';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  // <React.StrictMode>
  <BrowserRouter>
    <ThemeProvider theme={appTheme}>
      <ErrorState>
        <ApolloProvider client={apolloClient}>
          <AppState>
            <UserState>
              <CalculatorsState>
                <ApiMethodService>
                  <DisconnectHandlerWrapper />
                  <App />
                </ApiMethodService>
              </CalculatorsState>
            </UserState>
          </AppState>
        </ApolloProvider>
      </ErrorState>
    </ThemeProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
