/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, Typography, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import styles from './MuiSelect.module.scss';

const MuiSelect = ({
  name,
  items,
  label = '',
  value = null,
  filter = '',
  className = '',
  onChange = () => {},
  renderValue = null,
  addItemFunc = () => {},
  addItemText = '',
  multiple = false,
  disabled = false,
}) => {
  const onRenderValue = () => {
    if ((!value && value !== 0) || value.length === 0) {
      return (
        <Typography variant="placeholder" component="div">
          {label}
        </Typography>
      );
    }

    return renderValue ? renderValue(value) : value;
  };

  return (
    <FormControl className={className} type="select">
      <Select
        value={value}
        // label={label}
        name={name}
        displayEmpty
        onChange={onChange}
        renderValue={onRenderValue}
        multiple={multiple}
        disabled={disabled}
      >
        {items.length > 0 && label && (
          <Typography className={styles.listHeader} variant="placeholder" component="div">
            {label}
          </Typography>
        )}
        {items.map((item) => (
          <MenuItem key={item} value={item}>
            {renderValue ? renderValue(item) : item}
          </MenuItem>
        ))}
        {addItemText && addItemFunc && (
          <Button onClick={addItemFunc} startIcon={<AddIcon />} size="wide">
            {addItemText}
          </Button>
        )}
      </Select>
    </FormControl>
  );
};

MuiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  onChange: PropTypes.func,
  renderValue: PropTypes.func,
  filter: PropTypes.string,
  className: PropTypes.string,
  addItemFunc: PropTypes.func,
  addItemText: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MuiSelect;
