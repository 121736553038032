import React from 'react';

export const SupportChatCloseIcon = () => (
  <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line prettier/prettier
      d="M1.77 0.33L-4.32743e-07 2.1L9.9 12L19.8 2.1L18.03 0.329999L9.9 8.46L1.77 0.33Z"
      fill="white"
    />
  </svg>
);
