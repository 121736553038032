/* eslint-disable */

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IconButton, Modal, TextInput } from 'tt-ui-kit';
import { Button } from '@mui/material';
import FileUploader from '../../../components/fileUploader/FileUploader';
import styles from './ModalQuestion.module.scss';
import { ReactComponent as EditSvg } from '../../../assets/icons/edit.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/icons/delete2.svg';
import FilesStateList from '../../../components/filesStateList/FilesStateList';
import { CUSTOM_REPORT_STATES } from '../../../constants';

const ModalEditQuestion = ({ params }) => {
  const {
    isOpen,
    close,
    isGenerating,
    question,
    topicText,
    deleteItem,
    changeQuestion,
    saveDraftData,
    generateAnswer,
  } = params;
  const [editedQuestion, setEditedQuestion] = useState(false);
  const [editedAnswer, setEditedAnswer] = useState(false);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [isConfirmEditing, setIsConfirmEditing] = useState(false);
  const [isEditingAnswer, setIsEditingAnswer] = useState(false);

  const onClose = () => {
    setEditedQuestion(false);
    setEditedAnswer(false);
    setIsEditingQuestion(false);
    setIsEditingAnswer(!question.answerText);
    close();
  };

  const onSave = () => {
    saveDraftData(question.id);
    setEditedAnswer(false);
    setEditedQuestion(false);
  };

  const onEditQuestion = (value) => {
    const newQuestion = { id: question.id, questionText: value };
    changeQuestion(newQuestion);
    setEditedQuestion(true);
  };

  const onEditAnswer = (value) => {
    const newQuestion = { id: question.id, answerText: value };
    changeQuestion(newQuestion);
    setEditedAnswer(true);
  };

  const setEditing = (e) => {
    if (!isConfirmEditing) setIsWarningOpen(true);
    const { name } = e.currentTarget;
    if (name === 'question' && isConfirmEditing) {
      setIsEditingQuestion((prev) => !prev);
    }
    if (name === 'answer' && isConfirmEditing) {
      setIsEditingAnswer((prev) => !prev);
    }
  };

  const onDelete = () => {
    deleteItem('question', question.id);
    onClose();
  };

  const onConfirm = () => {
    setIsConfirmEditing(true);
    setIsWarningOpen(false);
  };

  const onCloseCallback = (filesList) => {
    const newQuestion = { id: question.id, filesExists: filesList.length > 0 };
    changeQuestion(newQuestion);
    // saveDraftData(question.id);
  };

  return (
    <>
      <Modal
        open={isWarningOpen}
        onClose={() => setIsWarningOpen(false)}
        closeOnlyByControls
        title="Do you really want to edit the question?"
        className={styles.modal}
        /* eslint-disable-next-line no-underscore-dangle */
        containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
      >
        <div className={styles.modalContent}>
          Please note that any changes you make will require a new answer generation.
        </div>
        <div className={styles.modalButtonsBlock}>
          <Button variant="outlined" onClick={() => setIsWarningOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onConfirm}>
            Ok
          </Button>
        </div>
      </Modal>
      <Modal
        open={isOpen}
        onClose={onClose}
        closeOnlyByControls
        title={
          <div className={styles.headerModal}>
            <div>View question</div>
            <div>
              <Button
                variant="text"
                onClick={onDelete}
                startIcon={<DeleteSvg fontSize="small" />}
                className={styles.modalDeleteButton}
              >
                Delete question
              </Button>
            </div>
          </div>
        }
        className={styles.modal}
        /* eslint-disable-next-line no-underscore-dangle */
        containerClassName={clsx(styles.__modalRoot, styles.modalWide)}
      >
        <div className={styles.modalContent}>
          <div className={styles.topicText}>{topicText}</div>
          <div>
            <div className={styles.subheader}>
              <h4>Question</h4>
              <IconButton name="question" onClick={setEditing} className={styles.questionButton}>
                <EditSvg />
              </IconButton>
            </div>
            {isEditingQuestion ? (
              <TextInput
                name="question"
                label="Question"
                className={styles.questionInput}
                inputProps={{ maxLength: 1800 }}
                value={question.questionText}
                onChange={(e) => onEditQuestion(e.target.value)}
                multiline
                maxRows={10}
                minRows={3}
              />
            ) : (
              <div className={styles.questionText}>{question.questionText}</div>
            )}
          </div>
          <div>
            <div className={styles.subheader}>
              <h4>Answer</h4>
              <IconButton name="answer" onClick={setEditing} className={styles.questionButton}>
                <EditSvg />
              </IconButton>
            </div>
            {isEditingAnswer ? (
              <TextInput
                name="answer"
                label="Answer"
                className={styles.questionInput}
                value={question.answerText ?? ''}
                onChange={(e) => onEditAnswer(e.target.value)}
                multiline
                maxRows={10}
                minRows={3}
              />
            ) : (
              <div className={styles.questionText}>{question.answerText}</div>
            )}
          </div>
        </div>
        <div className={styles.modalButtonsBlock}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {!question?.id ||
          !question?.state ||
          !question?.filesExists ||
          question?.state === CUSTOM_REPORT_STATES.NONE ? (
            <FileUploader
              questionCode={question.id ?? ''}
              btnText="Add documents"
              btnType="default"
              disabled={!question.id}
              title="Upload your files"
              onCloseCallback={onCloseCallback}
            />
          ) : (
            <FilesStateList questionCode={question.id} text={question.questionText} />
          )}
          <Button
            variant="outlined"
            onClick={() => generateAnswer('custom_report_question', question.id)}
            disabled={!question.id || isGenerating || !question.filesExists}
          >
            Generate answer
          </Button>
          {editedAnswer || editedQuestion ? (
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
          ) : (
            <Button variant="contained" onClick={onClose}>
              Ok
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

ModalEditQuestion.propTypes = {
  params: PropTypes.shape({
    isOpen: PropTypes.bool,
    topicText: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    question: PropTypes.object,
    parentTopicId: PropTypes.string,
    close: PropTypes.func,
    deleteItem: PropTypes.func,
    generateAnswer: PropTypes.func,
    changeQuestion: PropTypes.func,
    saveDraftData: PropTypes.func,
    isGenerating: PropTypes.bool,
  }),
};

export default ModalEditQuestion;
