/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'tt-ui-kit';
import { useLazyQuery } from '@apollo/client';
import { ReactComponent as Doc } from '../../assets/icons/doc.svg';
import styles from './FilesStateList.module.scss';
import FileState from '../fileState/FileState';
import { GET_QUESTIONS_FILE } from '../../api/apollo/api';
import { ErrorContext, UserContext } from '../../context';
import FileUploader from '../fileUploader/FileUploader';

const FilesStateList = ({ questionCode, title, text, disabled }) => {
  const { draftId } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);
  const [getFiles] = useLazyQuery(GET_QUESTIONS_FILE, {
    fetchPolicy: 'no-cache',
  });

  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [needRefresh, setNeedRefresh] = useState(false);

  const onCloseCallback = () => {
    setNeedRefresh(true);
  };

  const onButtonClick = (value) => {
    setFilesModalOpen(value);
  };

  const loadFiles = () => {
    getFiles({
      variables: {
        input: {
          draft_id: draftId,
          questions_code: questionCode,
        },
      },
    })
      .then((res) => {
        const loaded = res?.data?.getQuestionsFile;
        setFilesList([...loaded]);
      })
      .catch((err) => {
        setErrorAlert('Error loading files. Please try again later.', err);
      })
      .finally(() => {
        setNeedRefresh(false);
      });
  };

  useEffect(() => {
    if (filesModalOpen) {
      loadFiles();
    }
    return () => {
      setFilesList([]);
    };
  }, [filesModalOpen, needRefresh]);

  return (
    <>
      <Button
        type="default"
        onClick={() => onButtonClick(true)}
        startIcon={<Doc />}
        disabled={disabled}
      >
        View docs
      </Button>
      {questionCode && (
        <Modal
          open={filesModalOpen}
          onClose={() => onButtonClick(false)}
          className={styles.filesModal}
          title={title}
        >
          <div className={styles.question}>{text}</div>
          <div className={styles.filesWrapper}>
            <div className={styles.filesContainer}>
              {filesList?.length
                ? filesList.map((file) => <FileState key={file.uuidName} file={file} />)
                : `No files found for this question`}
            </div>
          </div>
          <div className={styles.buttonBlock}>
            <Button type="default" onClick={() => setFilesModalOpen(false)}>
              Exit
            </Button>
            <FileUploader
              questionCode={questionCode ?? ''}
              title="Upload your files"
              btnText="Edit docs"
              btnType="default"
              disabled={!questionCode}
              onCloseCallback={onCloseCallback}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

FilesStateList.propTypes = {
  questionCode: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FilesStateList;
