/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { SET_ERROR_ALERT } from '../types';

import ErrorContext from './errorContext';
import errorReducer from './errorReducer';

const ErrorState = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  // const [error, setError] = useState(null);
  // const [errorInfo, setErrorInfo] = useState(null);

  const initialState = {
    openErrorAlert: false,
    errorAlert: null,
  };

  const [state, dispatch] = useReducer(errorReducer, initialState);

  const logErrorInSession = (message) => {
    const savedError = window.localStorage.getItem('error');
    const errorArr = savedError ? JSON.parse(savedError) : [];
    errorArr.push({ date: `error ${new Date().toISOString()}`, message });
    window.localStorage.setItem('error', JSON.stringify(errorArr.slice(-100)));
  };

  const setErrorAlert = (errorAlert, err, callback = null) => {
    const payload = {
      errorAlert,
      openErrorAlert: !!errorAlert,
      callback,
    };
    if (process.env.REACT_APP_IS_LOCAL) console.log(err);
    logErrorInSession(err);

    dispatch({
      type: SET_ERROR_ALERT,
      payload,
    });
  };

  const onErrorMessageClose = () => {
    if (state.callback) state.callback();
    setErrorAlert(null);
  };

  useEffect(() => {
    const errorHandler = (err) => {
      setHasError(true);
      // setError(err);
      // setErrorInfo(info);

      // отправить ошибку на сервер
      // logErrorToMyService(error, errorInfo);
      if (process.env.REACT_APP_IS_LOCAL) console.log(err);
      logErrorInSession(err.message);
    };
    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  useEffect(() => {
    if (hasError)
      setErrorAlert('Something went wrong. Please refresh the page or try again later.');
  }, [hasError]);

  return (
    <ErrorContext.Provider value={{ setErrorAlert }}>
      <>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={state.openErrorAlert}
          onClose={() => onErrorMessageClose()}
        >
          <Alert
            onClose={() => onErrorMessageClose()}
            severity="error"
            sx={{ width: '100%', backgroundColor: '#D32F2F', color: '#ffffff' }}
          >
            {state.errorAlert}
          </Alert>
        </Snackbar>
        {children}
      </>
    </ErrorContext.Provider>
  );
};

ErrorState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorState;
