/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useReducer } from 'react';

import { SET_APP_LOADING, SET_APP_LOADED } from '../types';

import AppContext from './appContext';
import appReducer from './appReducer';

const AppState = ({ children }) => {
  const initialState = {
    loading: null,
    loaded: null,
  };

  const [state, dispatch] = useReducer(appReducer, initialState);

  const changeAppLoading = (loading = null) => {
    dispatch({
      type: SET_APP_LOADING,
      payload: loading > 0.98 ? 1 : loading,
    });
  };

  const changeAppLoaded = (loaded = null) => {
    dispatch({
      type: SET_APP_LOADED,
      payload: loaded,
    });
  };

  return (
    <AppContext.Provider
      value={{
        loading: state.loading,
        changeAppLoading,
        loaded: state.loaded,
        changeAppLoaded,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppState.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppState;
