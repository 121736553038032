import React from 'react';

export const CloseModalIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    {/* eslint-disable-next-line prettier/prettier */}
    <path
      d="M15.2842 14.2158C15.425 14.358 15.504 14.5501 15.504 14.7502C15.504 14.9504 15.425 15.1424 15.2842 15.2846C15.1408 15.4232 14.9492 15.5007 14.7498 15.5007C14.5504 15.5007 14.3588 15.4232 14.2154 15.2846L7.99982 9.05959L1.7842 15.2846C1.64084 15.4232 1.44924 15.5007 1.24982 15.5007C1.05041 15.5007 0.858804 15.4232 0.715449 15.2846C0.574613 15.1424 0.495605 14.9504 0.495605 14.7502C0.495605 14.5501 0.574613 14.358 0.715449 14.2158L6.94045 8.00022L0.715449 1.78459C0.595852 1.63887 0.534733 1.45387 0.543981 1.26558C0.553228 1.07729 0.632181 0.899174 0.765482 0.765873C0.898783 0.632572 1.0769 0.553619 1.26519 0.544372C1.45348 0.535124 1.63848 0.596243 1.7842 0.715841L7.99982 6.94084L14.2154 0.715841C14.3612 0.596243 14.5462 0.535124 14.7345 0.544372C14.9227 0.553619 15.1009 0.632572 15.2342 0.765873C15.3675 0.899174 15.4464 1.07729 15.4557 1.26558C15.4649 1.45387 15.4038 1.63887 15.2842 1.78459L9.0592 8.00022L15.2842 14.2158Z"
      fill="#212121"
    />
  </svg>
);
