/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { TextInput } from 'tt-ui-kit';

const UiTextField = ({
  name,
  label = '',
  value = '',
  onChange = () => {},
  onClick = () => {},
  className = '',
  filter = null,
  required = true,
  sx = null,
  tooltipText = '',
  readOnly = false,
  blockWrongTyping = true,
  maxNumber = undefined,
  startIcon,
  endIcon,
  ...restProps
}) => {
  const [isEmptied, setIsEmptied] = useState(false);
  const [isValidated, setIsValidated] = useState(true);
  const [isExcess, setIsExcess] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [timeoutID, setTimeoutID] = useState(null);

  useEffect(() => setInputValue(value), [value]);

  const DebouncedChange = (e) => {
    if (!e?.target) return;
    onChange(e);
  };

  const handleChange = (e) => {
    if (blockWrongTyping && filter && !filter.test(e.target.value)) {
      if (e.target.value === '') setInputValue('');
      return;
    }
    const empty = e.target.value === '' || e.target.value === null;
    setIsEmptied(empty);
    const valid = filter ? filter.test(e.target.value) : true;
    setIsValidated(valid);
    const excess = maxNumber ? parseInt(e.target.value, 10) > maxNumber : false;
    setIsExcess(excess);
    setInputValue(e.target.value);

    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const newTimeoutId = setTimeout(() => {
      if (valid && !excess) DebouncedChange(e);
    }, 300);
    setTimeoutID(newTimeoutId);
  };

  const errorCheck = () => isEmptied || !isValidated || isExcess;
  const getHelperText = () => {
    if (isEmptied) return 'This is a required field. Please fill in this field';
    if (!isValidated) return 'Incorrect value';
    if (isExcess) return `Max value is ${maxNumber}`;
    return '';
  };

  return (
    <TextInput
      className={className}
      tooltipText={tooltipText}
      label={label}
      name={name}
      value={inputValue ?? ''}
      onChange={handleChange}
      helperText={getHelperText()}
      error={errorCheck()}
      sx={sx}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      readOnly={readOnly}
      {...restProps}
    />
  );
};

UiTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  required: PropTypes.bool,
  filter: PropTypes.instanceOf(RegExp),
  sx: PropTypes.object,
  tooltipText: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  readOnly: PropTypes.bool,
  valueLength: PropTypes.number,
  blockWrongTyping: PropTypes.bool,
  maxNumber: PropTypes.number,
};

export default UiTextField;
