import React from 'react';

const GriReport = () => {
  console.log('GriReport');

  return (
    <>
      <h1>GriReport</h1>
    </>
  );
};

GriReport.propTypes = {};

export default GriReport;
