/* eslint-disable */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'vanilla-lazyload';

const LAZY_CLASS = 'lazy';

/* eslint-disable camelcase */
const LAZY_LOAD_CONFIG = {
  elements_selector: `.${LAZY_CLASS}`,
};
/* eslint-enable camelcase */

// Only initialize it one time for the entire application.
if (!document.lazyLoadInstance) {
  document.lazyLoadInstance = new LazyLoad(LAZY_LOAD_CONFIG);
}

const LazyImage = ({ bg, className, ...rest }) => {
  useEffect(() => {
    document.lazyLoadInstance.update();
  }, [bg]);

  return <div alt="" className={className} data-bg={bg} {...rest} />;
};

LazyImage.propTypes = {
  className: PropTypes.string,
  bg: PropTypes.string,
};

export default LazyImage;
