import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Modal, Button } from 'tt-ui-lib/core';
import { ClockIcon } from 'tt-ui-lib/icons';
import styles from './Disconnect.module.scss';

export const Disconnect = ({
  logoutHandler,
  accessToken,
  logoutDelay,
  location,
  userId,
  getUser,
  domainValue,
}) => {
  const [isFirstMount, setIsFirstMount] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    if (countdown === 0) {
      logoutHandler();
    }
  }, [countdown]);

  useEffect(() => {
    let timeoutId;
    let modalTimeoutId;
    let countdownInterval;

    const startCountdown = () => {
      modalTimeoutId = setTimeout(() => {
        setShowModal(true);
        setCountdown(60);

        countdownInterval = setInterval(() => {
          setCountdown((prevCount) => {
            if (prevCount <= 1) {
              clearInterval(countdownInterval);
              return 0;
            }
            return prevCount - 1;
          });
        }, 1000);
      }, logoutDelay - 60000);
    };

    const resetTimer = (delay = logoutDelay) => {
      if (isFirstMount) {
        const dateTimeTmp = new Date().getTime();
        const lastActiveTimeTmp = Cookies.get('last_active', {
          domain: domainValue,
        });

        const lastActiveTime = Number(lastActiveTimeTmp);
        if (!Number.isNaN(lastActiveTime) && dateTimeTmp - lastActiveTime >= logoutDelay) {
          setIsFirstMount(false);
          logoutHandler();
          return;
        }

        setIsFirstMount(false);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (modalTimeoutId) {
        clearTimeout(modalTimeoutId);
        modalTimeoutId = undefined;
      }
      if (countdownInterval) {
        clearInterval(countdownInterval);
        countdownInterval = undefined;
      }

      startCountdown();

      if (delay === logoutDelay) {
        const lastActive = new Date().getTime();
        Cookies.set('last_active', lastActive, {
          domain: domainValue,
        });
      }

      timeoutId = setTimeout(() => {
        const dateTime = new Date().getTime();
        const lastActiveTime = Cookies.get('last_active', {
          domain: domainValue,
        });
        if (lastActiveTime && dateTime - +lastActiveTime >= logoutDelay) {
          logoutHandler();
        } else if (lastActiveTime) {
          const newLogoutDelay = logoutDelay - (dateTime - +lastActiveTime);
          resetTimer(newLogoutDelay);
        }
      }, delay);
    };

    const handleUserActivity = () => {
      setShowModal((prevState) => {
        if (!prevState) {
          resetTimer();
        }

        return prevState;
      });
    };

    const continueSession = () => {
      resetTimer();
    };

    resetTimer();

    window.document
      .getElementById('logout-modal-exit-btn')
      ?.addEventListener('click', continueSession);
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.document
        .getElementById('logout-modal-exit-btn')
        ?.removeEventListener('click', continueSession);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (userId) {
          const user = await getUser();
          if (!user.data.currentUser) {
            logoutHandler();
          }
        } else if (accessToken) {
          logoutHandler();
        }
      } catch {
        logoutHandler();
      }
    })();
  }, [location]);

  return (
    <Modal open={showModal} closable={false}>
      <div className={styles.modal}>
        <div className={styles.title}>Session Expiring Soon</div>

        <div className={styles.mainBlock}>
          <div className={styles.text}>Your session will expire in</div>
          <div className={styles.counter}>
            <ClockIcon
              style={{
                height: 40,
                width: 40,
              }}
              className={styles.icon}
            />
            <span>
              {countdown === 60 ? '1:00' : countdown < 10 ? `0:0${countdown}` : `0:${countdown}`}
            </span>
          </div>
          <div className={styles.text}>Would you like to continue your session?</div>
        </div>

        <div className={styles.btns}>
          <Button onClick={() => logoutHandler()}>Logout</Button>
          <Button type="primary" id="logout-modal-exit-btn" onClick={() => setShowModal(false)}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};
