import {
  SET_PACKAGE_MATERIALS_DATA,
  SET_PRODUCT_MATERIALS_DATA,
  SET_CALCULATORS_LIST,
  SET_CARBON_SOURCES,
  SET_CALCULATOR_NAME,
  SET_CALCULATOR_COST,
  SET_LINKED_RESULT,
} from '../types';

const calculatorsReducer = (state, action) => {
  switch (action.type) {
    case SET_CALCULATOR_COST:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PRODUCT_MATERIALS_DATA:
      return {
        ...state,
        productMaterials: action.payload,
      };
    case SET_PACKAGE_MATERIALS_DATA:
      return {
        ...state,
        packageMaterials: action.payload,
      };
    case SET_CALCULATORS_LIST:
      return {
        ...state,
        selectedCalculators: [...action.payload.selectedCalculators],
      };
    case SET_CALCULATOR_NAME:
      return {
        ...state,
        calculatorName: action.payload,
      };
    case SET_CARBON_SOURCES:
      return {
        ...state,
        carbonSources: { ...action.payload.carbonSources },
        chartData: action.payload.chartData,
      };
    case SET_LINKED_RESULT:
      return {
        ...state,
        linkedResult: { ...action.payload },
      };
    default:
      return state;
  }
};

export default calculatorsReducer;
