/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'tt-ui-kit';

const ERRORS = {
  REQUIRED: 'required',
  RANGEOUT: 'rangeout',
  INVALID: 'invalid',
};

const ERROR_TEXT = {
  [ERRORS.RANGEOUT]: 'The date is out of range.',
  [ERRORS.INVALID]: 'The input date is not valid.',
};

const UiDatePicker = ({
  value = null,
  label = '',
  className = '',
  onChange = () => {},
  minDate = null,
  maxDate = null,
  onlyYear = false,
  disabled = false,
  clearable = false,
  name,
  required,
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [timeoutID, setTimeoutID] = useState(null);
  const [helperText, setHelperText] = useState(null);

  const getError = () => {
    if (required && !inputValue) {
      return ERRORS.REQUIRED;
    }
    if (inputValue && inputValue.toString() === 'Invalid Date') {
      return ERRORS.INVALID;
    }
    if (inputValue && ((maxDate && maxDate < inputValue) || (minDate && minDate > inputValue))) {
      return ERRORS.RANGEOUT;
    }
    return null;
  };

  useEffect(() => setInputValue(value), [value]);
  useEffect(
    () => setHelperText(ERROR_TEXT[getError()] ?? ''),
    [inputValue, required, minDate, maxDate]
  );

  const DebouncedChange = (newValue) => {
    setInputValue(newValue);
    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
    const newTimeoutId = setTimeout(() => {
      const val = e.target.value;
      DebouncedChange(val);
    }, 300);
    setTimeoutID(newTimeoutId);
  };

  return (
    <DatePicker
      label={label}
      name={name}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      value={inputValue}
      onChange={handleChange}
      className={className}
      onlyYear={onlyYear}
      required={required}
      clearable={clearable}
      helperText={helperText}
      error={!!helperText}
      {...restProps}
    />
  );
};

UiDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onlyYear: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  required: PropTypes.bool,
  clearable: PropTypes.bool,
};

export default UiDatePicker;
