/* eslint-disable camelcase */
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';

import { TextInput } from 'tt-ui-kit';
import { Grid, Button, IconButton, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as GeneratingIco } from '../../../assets/icons/generating.svg';
import { ReactComponent as DeleteSvg } from '../../../assets/icons/delete2.svg';
import { ReactComponent as OkSvg } from '../../../assets/icons/ok.svg';
import { ReactComponent as ErrorSvg } from '../../../assets/icons/doc-err.svg';
import { ReactComponent as EmptySvg } from '../../../assets/icons/doc-empty.svg';

import Form from '../../../components/form/Form';
import Pagination from '../../../components/pagination/Pagination';
import { ErrorContext, UserContext } from '../../../context';

import styles from './CustomReport.module.scss';
import pageStyles from '../../pages.module.scss';
import ModalCreateQuestion from './ModalCreateQuestion';
import ModalEditQuestion from './ModalEditQuestion';
import { CUSTOM_REPORT_STATES } from '../../../constants';
import TooltipState from '../../../components/tooltipState/TooltipState';

import {
  getReportDataByPage as getCalcQuestion,
  setReportData as updateCalcQuestion,
  deleteReportData as deleteCalcQuestion,
  newQuestion as createCalcQuestion,
  newTopic as createCalcTopic,
  getDraftById,
} from '../../../api/rest/list';

const ttRestHost = process.env.REACT_APP_REST_API;

const CustomReport = () => {
  const { draftId, user } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const { pathname } = useLocation();
  const { pageNum } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState({
    loadingCreate: false,
    loadingUpdate: false,
    loadingDelete: false,
    loadingSetAnswer: false,
    loadingData: false,
  });
  const [questionsList, setQuestionsList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);
  const [topTopic, setTopTopic] = useState(null);
  const [paginatorInfo, setPaginatorInfo] = useState(null);
  const [editQuestionId, setEditQuestionId] = useState(null);
  const [editParentTopicId, setEditParentTopicId] = useState(null);
  const [isQuestionEditModalOpen, setIsQuestionEditModalOpen] = useState(false);
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] = useState(false);

  const [needSave, setNeedSave] = useState(false);
  const [reportId, setReportId] = useState(false);
  const getIsLoading = () => Object.values(isLoading).some((v) => v);
  const canGenerate = !questionsList.some(
    (q) => !!q.filesExists && !!q.questionText && q.state !== CUSTOM_REPORT_STATES.PROCESSING
  );

  const setCurrentLoading = (name, value) => {
    setIsLoading((prev) => ({ ...prev, [name]: value }));
  };

  const closeQuestionsModal = () => {
    setIsQuestionCreateModalOpen(false);
    setIsQuestionEditModalOpen(false);
    setEditQuestionId(null);
  };

  const openQuestionModal = (id, action) => {
    if (action === 'create') {
      setEditParentTopicId(id);
      setIsQuestionCreateModalOpen(true);
    } else {
      setEditQuestionId(id);
      setIsQuestionEditModalOpen(true);
    }
  };

  const addTopic = (addArgs) => {
    const { parentId, isNavigate } = addArgs;
    setCurrentLoading('loadingCreate', true);
    createCalcTopic({
      report_id: reportId,
      data: { parent_topic_id: parentId ?? null },
    })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { topic: newTopic } = data;
          const newTopics = [...topicsList, { ...newTopic }];
          setTopicsList(newTopics);
          setTopTopic(newTopics.find((topic) => !topic.parentId));
        } else throw new Error('Cannot create new topic');
      })
      .catch((err) => {
        setErrorAlert(err.message, err);
      })
      .finally(() => {
        if (isNavigate) {
          const url = pathname.replace(pageNum, parseInt(paginatorInfo?.total ?? 1, 10) + 1);
          navigate(url);
        }
        setCurrentLoading('loadingCreate', false);
      });
  };

  const addQuestion = (addArgs) => {
    const { parentId, questionText } = addArgs;
    setCurrentLoading('loadingCreate', true);
    createCalcQuestion({
      report_id: reportId,
      data: {
        parent_topic_id: parentId,
        question_text: questionText,
      },
    })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { question } = data;
          const newQuestions = [...questionsList, { ...question }];
          setQuestionsList(newQuestions);
          setEditQuestionId(question.id);
        } else throw new Error('Cannot create new question');
      })
      .catch((err) => {
        setErrorAlert(err.message, err);
      })
      .finally(() => {
        setCurrentLoading('loadingCreate', false);
      });
  };

  const getQuestions = () => {
    setCurrentLoading('loadingData', true);
    getCalcQuestion({
      report_id: reportId,
      first: 1,
      page: parseInt(pageNum, 10),
    })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { questions, topics, paginatorInfo: newPaging } = data;
          if (!topics?.length) {
            addTopic({});
          } else {
            setQuestionsList([...questions]);
            setTopicsList(topics);
            setTopTopic(topics.find((topic) => !topic.parentTopicId));
            setPaginatorInfo(newPaging);
          }
        }
      })
      .catch((err) => {
        setErrorAlert(err.message, err);
      })
      .finally(() => setCurrentLoading('loadingData', false));
  };

  const deleteItem = (type, itemId) => {
    setCurrentLoading('loadingDelete', true);
    deleteCalcQuestion({
      report_id: reportId,
      data: { type, id: itemId },
    })
      .catch((err) => {
        setErrorAlert(err.message, err);
      })
      .finally(() => {
        getQuestions();
        setCurrentLoading('loadingDelete', false);
      });
  };

  const refreshItems = (questions, topics) => {
    const newQuestions = questionsList.map((q) => {
      const newQ = questions.find((newQuestion) => newQuestion.id === q.id);
      return newQ ?? q;
    });
    setQuestionsList([...newQuestions]);
    const newTopics = topicsList.map((t) => {
      const newT = topics.find((newTopic) => newTopic.id === t.id);
      return newT ?? t;
    });
    setTopicsList([...newTopics]);
  };

  /* update json data example:
    {
        "report_id": "33333333-4306-4ad9-a5e8-f2b5e42044e9",
        "data": {
            topics: [
                { id: "e7c23702-0064-4518-9fa5-4b071f61cbaa", topic_text: "text2" }
            ],
            questions: [
                { id: "10a745f0-c60f-483d-9403-e335cb638ae2", question_text: "text" }
                { id: "7810f57c-028e-47af-848c-d49af98e2b02", question_text: "text1", answer_text: "text1" },
            ]
        }
    }
    */
  const saveDraftData = (filterId = null) => {
    setCurrentLoading('loadingSave', true);
    updateCalcQuestion({
      report_id: reportId,
      data: {
        topics: topicsList
          .filter((t) => t.edited)
          .map((t) => ({ id: t.id, topic_text: t.topicText })),
        questions: questionsList
          .filter((q) => q.edited && (filterId == null || filterId === q.id))
          .map((q) => ({
            id: q.id,
            question_text: q.questionText,
            answer_text: q.answerText,
            description: q.description,
          })),
      },
    })
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { questions, topics } = data;
          refreshItems(questions, topics);
        }
      })
      .catch((err) => {
        setErrorAlert(err.message, err);
      })
      .finally(() => {
        setCurrentLoading('loadingSave', false);
        setNeedSave(false);
      });
  };

  useEffect(() => {
    if (!draftId) return;
    getDraftById(draftId)
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (success) {
          const { data: draft } = resp.data;
          setReportId(draft?.reportId);
        }
      })
      .catch((err) => {
        setErrorAlert('Cannot load the draft, please thy again later', err);
      });
  }, [draftId]);

  useEffect(() => {
    if (!reportId) return;
    getQuestions();
  }, [pageNum, reportId]);

  useEffect(() => {
    if (needSave) saveDraftData();
  }, [needSave]);

  const onNextClick = () => {
    setCurrentLoading('loadingData', true);
    const url = pathname.replace(pageNum, parseInt(pageNum, 10) + 1);
    navigate(url);
  };

  const onFinishClick = () => {
    navigate('/drafts');
  };

  const onBackClick = () => {
    setCurrentLoading('loadingData', true);
    if (parseInt(pageNum, 10) === 1) {
      navigate('/registration');
    } else {
      const url = pathname.replace(pageNum, parseInt(pageNum, 10) - 1);
      navigate(url);
    }
  };

  const changeTopic = (e, topicId) => {
    const newTopics = topicsList.map((topic) =>
      topic.id !== topicId
        ? topic
        : {
            ...topic,
            topicText: e.target.value,
            edited: true,
          }
    );
    setTopicsList([...newTopics]);
  };

  const changeQuestion = (newQuestion) => {
    const newQuestions = questionsList.map((question) =>
      question.id !== newQuestion.id
        ? question
        : {
            ...question,
            ...newQuestion,
            edited: true,
          }
    );
    setQuestionsList([...newQuestions]);
  };

  const getList = (id) => {
    const list = questionsList?.filter(
      (question) => question.customReportTopicId === id && !!question.questionText
    );
    return list;
  };

  const getText = (type, id) => {
    if (type === 'topic') {
      return topicsList.find((topic) => topic.id === id)?.topicText ?? '';
    }
    if (type === 'question') {
      return questionsList.find((question) => question.id === id)?.questionText ?? '';
    }
    return '';
  };

  const unsaved =
    topicsList.some((topic) => topic.edited) || questionsList.some((question) => question.edited);

  const setStatuses = (isSuccess, source, id) => {
    const state = isSuccess ? CUSTOM_REPORT_STATES.PROCESSING : CUSTOM_REPORT_STATES.ERROR;
    if (source === 'custom_report_question') {
      const newQuestion = questionsList.find((question) => question.id === id);
      const qState = newQuestion.filesExists ? state : CUSTOM_REPORT_STATES.NO_DATA;
      changeQuestion({ ...newQuestion, qState });
    }
    if (source === 'custom_report_topic') {
      const newQuestions = questionsList.map((question) => ({
        ...question,
        state: question.filesExists ? state : CUSTOM_REPORT_STATES.NO_DATA,
        edited: true,
      }));
      setQuestionsList([...newQuestions]);
    }
  };

  // source_id=0daae954-c373-4304-b89e-0e3c04a0937f&source=custom_report_question
  const generateAnswer = (source, id) => {
    const token = Cookies.get('access_token');
    const headers = {
      authorization: token ? `Bearer ${token}` : '',
      Userid: user.id,
    };
    const params = {
      source_id: id,
      source,
    };
    const url = `${ttRestHost}/tt-ai/answers/generate`;

    axios
      .get(url, { headers, params })
      .then((response) => {
        const { data } = response;
        if (data?.success) setStatuses(true, source, id);
      })
      .catch((err) => {
        setErrorAlert(err.message, err);
        setStatuses(false, source, id);
      })
      .finally(() => setNeedSave(true));
  };

  const getQuestionIco = (state) => {
    switch (state) {
      case CUSTOM_REPORT_STATES.PROCESSING:
        return <GeneratingIco className={pageStyles.rotating} />;
      case CUSTOM_REPORT_STATES.SUCCESS:
        return <OkSvg />;
      case CUSTOM_REPORT_STATES.ERROR:
        return <ErrorSvg />;
      case CUSTOM_REPORT_STATES.NO_DATA:
        return <EmptySvg />;
      default:
        return <div />;
    }
  };

  const getQuestionTooltip = (state) => {
    switch (state) {
      case CUSTOM_REPORT_STATES.PROCESSING:
        return 'Generating an answer.';
      case CUSTOM_REPORT_STATES.SUCCESS:
        return 'Answer successfully generated.';
      case CUSTOM_REPORT_STATES.ERROR:
        return 'Is there something wrong. See the file list for details.';
      case CUSTOM_REPORT_STATES.NO_DATA:
        return 'There is no data in the files to answer.';
      default:
        return '';
    }
  };

  return (
    <>
      <ModalCreateQuestion
        params={{
          isOpen: isQuestionCreateModalOpen,
          close: closeQuestionsModal,
          isGenerating:
            questionsList.find((q) => q.id === editQuestionId)?.state ===
            CUSTOM_REPORT_STATES.PROCESSING,
          editQuestionId,
          parentTopicId: editParentTopicId,
          question: questionsList.find((q) => q.id === editQuestionId) ?? {},
          addQuestion,
          changeQuestion,
          saveDraftData: () => setNeedSave(true),
          generateAnswer,
        }}
      />
      <ModalEditQuestion
        params={{
          isOpen: isQuestionEditModalOpen,
          close: closeQuestionsModal,
          isGenerating:
            questionsList.find((q) => q.id === editQuestionId)?.state ===
            CUSTOM_REPORT_STATES.PROCESSING,
          question: questionsList.find((q) => q.id === editQuestionId) ?? {},
          topicText: getText('topic', editParentTopicId),
          deleteItem,
          changeQuestion,
          saveDraftData: () => setNeedSave(true),
          generateAnswer,
        }}
      />
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        className={styles.formContainer}
      >
        <Form>
          {topTopic && (
            <>
              <div className={styles.rowContainer}>
                <TextInput
                  name="topic"
                  label="Topic"
                  className={styles.questionInput}
                  inputProps={{ maxLength: 600 }}
                  value={getText('topic', topTopic.id)}
                  onChange={(e) => changeTopic(e, topTopic.id)}
                  multiline
                  maxRows={8}
                  minRows={1}
                />
                <div className={styles.buttonBlock}>
                  <Button
                    variant="text"
                    onClick={() => addTopic({ parentId: topTopic.id })}
                    startIcon={<AddIcon />}
                    className={styles.questionButton}
                    disabled={getIsLoading()}
                  >
                    Add subtopic
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => openQuestionModal(topTopic.id, 'create')}
                    startIcon={<AddIcon />}
                    className={styles.questionButton}
                    disabled={getIsLoading()}
                  >
                    Add question
                  </Button>
                </div>
              </div>
              <div className={styles.childBlock}>
                {getList(topTopic.id).length > 0 && (
                  <div className={styles.questionBlock}>
                    {getList(topTopic.id).map((question) => (
                      <div key={question.id} className={styles.rowContainer}>
                        <div className={styles.questionText}>{question.questionText}</div>
                        <div className={styles.buttonBlock}>
                          <TooltipState
                            tooltipText={getQuestionTooltip(question.state)}
                            Icon={() => getQuestionIco(question.state)}
                          />
                          <Button
                            variant="outlined"
                            onClick={() => openQuestionModal(question.id)}
                            disabled={getIsLoading()}
                            className={styles.questionButton}
                          >
                            View more
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {topicsList
                  ?.filter((topic) => topic.parentTopicId === topTopic.id)
                  .map((topic) => (
                    <div key={topic.id}>
                      <div className={styles.rowContainer}>
                        <TextInput
                          name="subtopic"
                          label="Subtopic"
                          className={styles.questionInput}
                          inputProps={{ maxLength: 600 }}
                          value={getText('topic', topic.id)}
                          onChange={(e) => changeTopic(e, topic.id)}
                          multiline
                          maxRows={8}
                          minRows={1}
                        />
                        <div className={styles.buttonBlock}>
                          <IconButton
                            onClick={() => deleteItem('topic', topic.id)}
                            className={styles.questionButton}
                            disabled={getIsLoading()}
                          >
                            <DeleteSvg fontSize="small" />
                          </IconButton>
                          <Button
                            variant="text"
                            onClick={() => openQuestionModal(topic.id, 'create')}
                            startIcon={<AddIcon />}
                            className={styles.questionButton}
                            disabled={getIsLoading()}
                          >
                            Add question
                          </Button>
                        </div>
                      </div>
                      {getList(topic.id).length > 0 && (
                        <div key={topic.id} className={styles.childBlock}>
                          <div className={styles.questionBlock}>
                            {getList(topic.id).map((question) => (
                              <div key={question.id} className={styles.rowContainer}>
                                <div className={styles.questionText}>{question.questionText}</div>
                                <div className={styles.buttonBlock}>
                                  <TooltipState
                                    tooltipText={getQuestionTooltip(question.state)}
                                    Icon={() => getQuestionIco(question.state)}
                                  />
                                  <Button
                                    variant="outlined"
                                    onClick={() => openQuestionModal(question.id)}
                                    disabled={getIsLoading()}
                                    className={styles.questionButton}
                                  >
                                    View more
                                  </Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </>
          )}
        </Form>
        <div className={styles.navigation}>
          <div className={styles.pageInfo}>
            <Pagination
              activePage={paginatorInfo?.currentPage ?? 1}
              pagesCount={paginatorInfo?.total ?? 1}
            />
          </div>
          <div className={styles.footerButtons}>
            <Button variant="outlined" onClick={onBackClick} disabled={getIsLoading()}>
              {getIsLoading() ? (
                <CircularProgress
                  sx={{
                    width: '20px !important',
                    height: '20px !important',
                    margin: '13px !important',
                  }}
                />
              ) : (
                'Back'
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => generateAnswer('custom_report_topic', topTopic.id)}
              disabled={canGenerate || unsaved}
            >
              Generate answer
            </Button>
            {paginatorInfo?.total === paginatorInfo?.currentPage && (
              <Button
                variant="outlined"
                onClick={() => addTopic({ typeItem: 'topic', isNavigate: true })}
                disabled={unsaved || paginatorInfo?.total !== paginatorInfo?.currentPage}
              >
                Add topic
              </Button>
            )}
            {unsaved ? (
              <Button variant="contained" onClick={() => setNeedSave(true)}>
                Save
              </Button>
            ) : (
              <>
                {paginatorInfo?.currentPage < paginatorInfo?.total && (
                  <Button variant="contained" onClick={onNextClick} disabled={getIsLoading()}>
                    {getIsLoading() ? (
                      <CircularProgress
                        style={{ color: 'white' }}
                        sx={{
                          width: '20px !important',
                          height: '20px !important',
                          margin: '13px !important',
                        }}
                      />
                    ) : (
                      'Next'
                    )}
                  </Button>
                )}
                {paginatorInfo?.currentPage >= paginatorInfo?.total && (
                  <Button variant="contained" onClick={onFinishClick} disabled={getIsLoading()}>
                    Finish
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

CustomReport.propTypes = {};

export default CustomReport;
