import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import {
  API_FILES_GET_URL,
  API_FILES_SET_URL,
  API_FILES_ADD_URL,
  API_FILES_DELETE_URL,
  API_FILES_GET_UPLOAD_URL,
} from './urls';

export const uploadFileToAWS = async (url, file) =>
  axiosClient.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'Access-Control-Allow-Origin': '*',
    },
  });

export const getFiles = async (params) => {
  const transformResponse = ({ data, success }) => {
    const newData = data.map((file) =>
      Object.keys(file).reduce(
        (res, key) => ({
          ...res,
          [key === 'file' ? 'uuidName' : key]: file[key],
        }),
        {}
      )
    );
    return { data: newData, success };
  };

  return axiosClient.get(
    API_FILES_GET_URL,
    axiosSSOConfigPrivate({}, { params: params, shouldTransformResponse: transformResponse })
  );
};

export const getUploadUrl = async (params) =>
  axiosClient.get(API_FILES_GET_UPLOAD_URL, axiosSSOConfigPrivate({}, { params: params }));

export const setFiles = async (params) =>
  axiosClient.post(API_FILES_SET_URL, params, axiosSSOConfigPrivate());

export const deleteFiles = async (params) =>
  axiosClient.post(API_FILES_DELETE_URL, params, axiosSSOConfigPrivate());

export const addFiles = async (params) => {
  const transformResponse = ({ data, success }) => {
    const newData = Object.keys(data).reduce(
      (res, key) => ({
        ...res,
        [key === 'file' ? 'uuidName' : key]: data[key],
      }),
      {}
    );
    return { data: newData, success };
  };

  return axiosClient.post(
    API_FILES_ADD_URL,
    params,
    axiosSSOConfigPrivate({}, { shouldTransformResponse: transformResponse })
  );
};
