/* eslint-disable */

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import { CalculatorsContext } from '../../../context';
import { CalculatorsListData } from '../../../constants';

import NotFound from '../../notFound/NotFound';

import ProgressCircle from '../../../components/progressCircle/ProgressCircle';
import SelfAssessment from '../../../components/selfAssessment/SelfAssessment';

import styles from './CalculatorsRouter.module.scss';
import CustomReport from '../../reports/CustomReport/CustomReport.jsx';
import CustomFiles from '../../reports/CustomFiles/CustomFiles.jsx';
import GriReport from '../../reports/GriReport/GriReport.jsx';

const CalculatorsRouter = () => {
  const navigate = useNavigate();
  /* TODO сделать переход на последний вопрос */
  // const { pathname, state } = useLocation();
  const { pathname } = useLocation();
  const calculatorContainer = useRef();
  const { questions, carbonSources, selectedCalculators, calculatorName, companyQuestions } =
    useContext(CalculatorsContext);
  const [previousCount, setPreviousCount] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(1);
  const [questionCount, setQuestionCount] = useState(1);
  const chartColor = '#01A0C6';

  const [accordionItems, setAccordionItems] = useState([]);

  useEffect(() => {
    const availableCalculators = CalculatorsListData[calculatorName].children;
    if (availableCalculators && availableCalculators.length) {
      const isExists = availableCalculators.some((url) => pathname.includes(url));
      if (!isExists) navigate('/drafts');
    }
  }, [calculatorName, pathname]);

  useEffect(() => {
    if (!calculatorName || calculatorName === 'global') return;
    const calculators = selectedCalculators.map((c) => c.name);
    const companyCalc = calculators.includes('scope3')
      ? companyQuestions.reduce((res, q) => res + q.scope.length, 0)
      : 0;

    const localCalc = calculators.includes('local') ? questions.length : 0;

    const currentIndex = calculators.indexOf(calculators.find((c) => pathname.includes(c)));

    setPreviousCount(0);
    if (calculators.includes('scope3') && calculators.indexOf('scope3') < currentIndex) {
      setPreviousCount((value) => value + companyCalc);
    }
    if (calculators.includes('local') && calculators.indexOf('local') < currentIndex) {
      setPreviousCount((value) => value + localCalc);
    }
    setQuestionCount(companyCalc + localCalc);
  }, [carbonSources, questions, questionIndex]);

  const getFirstQuestionUrl = () =>
    selectedCalculators && selectedCalculators.length ? selectedCalculators[0].url : '/';

  return (
    <>
      <Grid
        item
        container
        alignItems="center"
        direction="column"
        className={styles.calculatorContainer}
        ref={calculatorContainer}
      >
        <Grid className={styles.breadcrumps}>
          TransparenTerra&nbsp;&nbsp;▷&nbsp;&nbsp;ESG&nbsp;&nbsp;▷&nbsp;&nbsp;
          <Link to="/">Report</Link>
          {accordionItems && accordionItems.length && (
            <span>
              &nbsp;&nbsp;▷&nbsp;&nbsp;
              <Link to={getFirstQuestionUrl()}>{accordionItems[0].name}</Link>
            </span>
          )}
        </Grid>
        <SelfAssessment />

        <Grid item container justifyContent="center" className={styles.content}>
          <ProgressCircle
            current={questionIndex + previousCount}
            count={questionCount}
            color={chartColor}
          />
          <Routes>
            <Route
              exact
              path="/custom_report/:pageNum"
              element={
                <CustomReport
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  calculatorContainerRef={calculatorContainer}
                />
              }
            />
            <Route
              exact
              path="/custom_files"
              element={
                <CustomFiles
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  calculatorContainerRef={calculatorContainer}
                />
              }
            />
            <Route
              exact
              path="/sust_gri"
              element={
                <GriReport
                  questionIndex={questionIndex}
                  setQuestionIndex={setQuestionIndex}
                  setQuestionCount={setQuestionCount}
                  setAccordionItems={setAccordionItems}
                  calculatorContainerRef={calculatorContainer}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Grid>
      </Grid>
    </>
  );
};

export default CalculatorsRouter;
