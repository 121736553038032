import React from 'react';

export const MessageLikeIcon = (color) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_141_10952)">
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d="M20.7633 12.5085C21.157 11.9882 21.375 11.3507 21.375 10.6874C21.375 9.63508 20.7867 8.63898 19.8398 8.08351C19.5961 7.94053 19.3185 7.86528 19.0359 7.86555H13.4156L13.5563 4.98508C13.5891 4.28898 13.343 3.62804 12.8648 3.12414C12.6302 2.87577 12.3471 2.67815 12.0331 2.54351C11.7191 2.40887 11.3807 2.34005 11.0391 2.34133C9.82031 2.34133 8.74219 3.16164 8.41875 4.33586L6.40547 11.6249H3.375C2.96016 11.6249 2.625 11.9601 2.625 12.3749V20.9062C2.625 21.321 2.96016 21.6562 3.375 21.6562H17.468C17.6836 21.6562 17.8945 21.614 18.0891 21.5296C19.2047 21.0538 19.9242 19.964 19.9242 18.7546C19.9242 18.4593 19.882 18.1687 19.7977 17.8874C20.1914 17.3671 20.4094 16.7296 20.4094 16.0663C20.4094 15.771 20.3672 15.4804 20.2828 15.1991C20.6766 14.6788 20.8945 14.0413 20.8945 13.378C20.8898 13.0827 20.8477 12.7898 20.7633 12.5085ZM4.3125 19.9687V13.3124H6.21094V19.9687H4.3125ZM19.2281 11.6952L18.7148 12.1405L19.0406 12.7359C19.148 12.932 19.2036 13.1522 19.2023 13.3757C19.2023 13.7624 19.0336 14.1304 18.743 14.3835L18.2297 14.8288L18.5555 15.4241C18.6628 15.6202 18.7185 15.8404 18.7172 16.064C18.7172 16.4507 18.5484 16.8187 18.2578 17.0718L17.7445 17.5171L18.0703 18.1124C18.1776 18.3085 18.2333 18.5287 18.232 18.7523C18.232 19.2773 17.9227 19.7507 17.4445 19.9663H7.71094V13.2374L10.043 4.7882C10.1031 4.57164 10.2322 4.38058 10.4107 4.24397C10.5891 4.10737 10.8073 4.03266 11.032 4.03117C11.2102 4.03117 11.3859 4.08273 11.5266 4.1882C11.7586 4.36164 11.8828 4.62414 11.8687 4.90304L11.6437 9.55305H19.0125C19.4297 9.80851 19.6875 10.2398 19.6875 10.6874C19.6875 11.0741 19.5188 11.4398 19.2281 11.6952Z"
        fill="#14711D"
      />
    </g>
    <defs>
      <clipPath id="clip0_141_10952">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
