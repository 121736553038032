import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { ReactComponent as LoadingLogo } from '../../assets/icons/loadingLogo.svg';

import styles from './Loading.module.scss';

import { AppContext } from '../../context';

const Loading = () => {
  const { loading, changeAppLoading, changeAppLoaded } = useContext(AppContext);
  useEffect(() => {
    changeAppLoading(null);
    changeAppLoaded(true);
  }, [loading === 1]);

  return (
    <div
      id="loadingsScreen"
      className={clsx(
        styles.container,
        loading === null || loading === 1 ? styles.stopped : '',
        loading === null ? styles.closed : ''
      )}
    >
      {loading !== null && <span className={styles.percents}>{Math.round(loading * 100)}%</span>}

      <LoadingLogo className={styles.logo} />

      {loading !== null && <span className={styles.loading}>Loading...</span>}
    </div>
  );
};

export default Loading;
