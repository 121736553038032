import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../context';

const ProtectedRoute = () => {
  const { user } = useContext(UserContext);
  return user.roleName !== 'individual' && user.roleName !== 'digital' ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
