/* eslint-disable */

import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { IconButton } from '@mui/material';
import Dot3Menu from '../dot3menu/Dot3Menu';

import { ReactComponent as GeneratingIco } from '../../assets/icons/generating.svg';
import { ReactComponent as OkSvg } from '../../assets/icons/ok.svg';
import { ReactComponent as ErrorSvg } from '../../assets/icons/doc-err.svg';
import { ReactComponent as EmptySvg } from '../../assets/icons/doc-empty.svg';
import { ReactComponent as DocSvg } from '../../assets/icons/doc-ok.svg';

import styles from './FileState.module.scss';
import pageStyles from '../../views/pages.module.scss';

import { downloadFileFromURL } from '../../utils';
import TooltipState from '../tooltipState/TooltipState';
import { ErrorContext, UserContext } from '../../context';

const ttRestHost = `${process.env.REACT_APP_REST_API}/tt-documents/docs`;

const icoStyle = {
  width: 20,
  height: 20,
  flexShrink: 0,
  fontSize: 20,
  padding: 0,
};

const STATUSES = {
  NONE: 'NONE',
  PROCESSING: 'PROCESSING',
  NOT_EXISTS: 'NOT_EXISTS',
  NOT_READABLE: 'NOT_READABLE',
  SUCCESS: 'SUCCESS',
  NO_DATA: 'NO_DATA',
  NO_ANSWER: 'NO_ANSWER',
};

const getQuestionTooltip = (state) => {
  switch (state) {
    case STATUSES.PROCESSING:
      return 'The file is being parsed.';
    case STATUSES.SUCCESS:
      return 'File parsed successfully.';
    case STATUSES.NOT_EXISTS:
      return 'File does not exist.';
    case STATUSES.NOT_READABLE:
      return 'The file is damaged or cannot be opened.';
    case STATUSES.NO_DATA:
      return 'No available data.';
    case STATUSES.NO_ANSWER:
      return 'No data available to generating answer.';
    default:
      return 'File is uploaded';
  }
};

const getQuestionIco = (state) => {
  switch (state) {
    case STATUSES.PROCESSING:
      return <GeneratingIco className={pageStyles.rotating} />;
    case STATUSES.SUCCESS:
      return <OkSvg />;
    case STATUSES.NOT_EXISTS:
      return <ErrorSvg />;
    case STATUSES.NOT_READABLE:
      return <ErrorSvg />;
    case STATUSES.NO_DATA:
      return <EmptySvg />;
    case STATUSES.NO_ANSWER:
      return <EmptySvg />;
    default:
      return <DocSvg />;
  }
};

const FileState = ({ file }) => {
  const { user } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);
  const [isDescriptionShown, setIsDescriptionShown] = useState(false);

  const downloadFile = (url) => {
    downloadFileFromURL(url, file.originalName);
  };

  const generateLink = () => {
    const token = Cookies.get('access_token');
    const headers = {
      authorization: token ? `Bearer ${token}` : '',
      Userid: user.id,
    };
    const par = `?name=${file.uuidName}`;
    const url = `${ttRestHost}/downloadUrl${par}`;

    axios
      .get(url, { headers })
      .then((response) => {
        const { data } = response;
        if (data?.success) {
          downloadFile(data.url);
        }
      })
      .catch((err) => setErrorAlert('Error loading files. Please try again later.', err));
  };

  const onMenuClick = (option) => {
    if (option === 'Download') generateLink();
  };

  return (
    <div className={styles.fileContainer}>
      <div className={styles.fileRow}>
        <div className={styles.fileName} title={file.originalName}>
          <div className={styles.svg}>
            <TooltipState
              tooltipText={getQuestionTooltip(file.state)}
              Icon={() => getQuestionIco(file.state)}
            />
          </div>
          <div className={styles.text}>{file.originalName}</div>
        </div>
        <div className={styles.buttons}>
          {file.description && (
            <IconButton onClick={() => setIsDescriptionShown((value) => !value)}>
              {isDescriptionShown ? (
                <ExpandLessIcon style={icoStyle} />
              ) : (
                <ExpandMoreIcon style={icoStyle} />
              )}
            </IconButton>
          )}
          <Dot3Menu params={{ onMenuClick }} />
        </div>
      </div>
      {isDescriptionShown && <div className={styles.description}>{file.description}</div>}
    </div>
  );
};

FileState.propTypes = {
  file: PropTypes.object,
};

export default FileState;
