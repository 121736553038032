import axios from 'axios';
import Cookies from 'js-cookie';
import { API_BASE_REPORT_API_URL } from './urls';
import { snakeToCamel } from '../../utils';

const axiosClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

/* eslint-disable no-param-reassign */
axiosClient.interceptors.response.use(
  (response) => {
    if (response.data) response.data = { ...snakeToCamel(response.data) };
    if (response.data && typeof response.config?.shouldTransformResponse === 'function')
      response.data = { ...response.config.shouldTransformResponse(response.data) };
    return response;
  },
  (error) => Promise.reject(error)
);
/* eslint-enable no-param-reassign */

const axiosSSOConfigPrivate = (headers, options) => {
  const accessToken = Cookies.get('access_token');
  const userId = Cookies.get('userId');

  return {
    headers: {
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      Userid: userId,
      ...headers,
    },
    ...options,
  };
};

export { axiosClient, axiosSSOConfigPrivate };
