import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import { setContext } from '@apollo/client/link/context';
import Cookies from 'js-cookie';

const httpLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      sso: process.env.REACT_APP_SSO_GRAPHQL_ENDPOINT,
      calculator: process.env.REACT_APP_CALCULATOR_GRAPHQL_ENDPOINT,
      market: process.env.REACT_APP_MARKET_GRAPHQL_ENDPOINT,
    },
    httpSuffix: '',
    createHttpLink: () => createHttpLink(),
  }),
]);

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get('access_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, httpLink]),
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
  cache: new InMemoryCache(),
});

export default client;
