import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDraftById } from '../../api/rest/list';
import { CalculatorsContext, ErrorContext, UserContext } from '../../context';
import { isDraftFilled } from '../../utils';

const EditAssessment = () => {
  const navigate = useNavigate();
  const { draftId } = useParams();

  const { selectedCalculators, createCalculatorList } = useContext(CalculatorsContext);
  const { draft, setDraft, prepareDraft } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const getDraftData = () => {
    getDraftById(draftId)
      .then((resp) => {
        const { data, success } = resp?.data ?? {};
        if (!success) {
          setErrorAlert('Draft was not found', null, () => navigate('/drafts', { replace: true }));
        }
        setDraft({ ...data });
      })
      .catch((err) => {
        setErrorAlert('Draft loading error', err);
      });
  };

  useEffect(() => {
    setDraft(null);
  }, []);

  useEffect(() => {
    if (draft) {
      const name = draft.type;
      if (name) {
        createCalculatorList(name);
      }
    } else {
      getDraftData();
    }
  }, [draft]);

  useEffect(() => {
    getDraftData();
  }, [draftId]);

  useEffect(() => {
    if (!selectedCalculators || !selectedCalculators.length || !draft) return;
    if (draft && isDraftFilled(draft)) {
      if (draft.lastPosition) navigate(draft.lastPosition);
      else navigate(selectedCalculators[0].url, { replace: true });
    } else navigate('/registration', { replace: true });
  }, [selectedCalculators, draft]);

  return <></>;
};

export default EditAssessment;
