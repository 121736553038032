import { API_BASE_REPORT_API_URL } from '../../urls';

export const API_DRAFTS_URL = `${API_BASE_REPORT_API_URL}/drafts`;

export const API_DRAFTS_NEW_URL = `${API_DRAFTS_URL}/new`;
export const API_DRAFTS_REPLACE_URL = `${API_DRAFTS_URL}/replace`;
export const API_DRAFTS_UPDATE_URL = `${API_DRAFTS_URL}/set`;
export const API_DRAFTS_DELETE_URL = `${API_DRAFTS_URL}/del`;
export const API_DRAFT_GET_BY_ID_URL = `${API_DRAFTS_URL}/get`;
export const API_DRAFTS_GET_URL = `${API_DRAFTS_URL}/filter`;
