/* eslint-disable */

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@mui/material';
import UiDatePicker from '../../components/uiDatePicker/UiDatePicker';

import styles from './SecondStep.module.scss';
import MuiSelect from '../../components/muiSelect/MuiSelect';
import UiTextField from '../../components/uiTextField/UiTextField';
import FileUploader from '../../components/fileUploader/FileUploader';

const languages = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  es: 'Español',
};

const RegistrationCustom = ({ onChange, openDisclaimer, data, dateDisable }) => (
  <>
    <Button
      className={styles.disclaimerBtn}
      size="small"
      color="lightBlue"
      onClick={openDisclaimer}
    >
      Basic details*
    </Button>
    <UiTextField
      className={clsx(styles.input, styles.wide)}
      label="Name *"
      name="name"
      value={((data && data.name) ?? '').toString()}
      onChange={onChange}
    />
    <MuiSelect
      className={clsx(styles.input, styles.wide)}
      label="Language"
      name="language"
      value={data.language || 'en'}
      onChange={onChange}
      renderValue={(ln) => languages[ln]}
      items={Object.keys(languages) || []}
      disabled={dateDisable}
    />
    <UiDatePicker
      label="Reporting date"
      name="periodReportDate"
      onChange={onChange}
      value={(data && data.periodReportDate) ?? null}
      disabled={dateDisable}
      minDate={new Date(2000, 1, 1)}
      maxDate={new Date(new Date().getFullYear(), 11, 31)}
      className={clsx(styles.input, styles.wide)}
      required
      clearable
    />
    <div>
      <FileUploader questionCode="registration" disabled={!data.reportId} />
    </div>
  </>
);

RegistrationCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
  openDisclaimer: PropTypes.func.isRequired,
  numberFilter: PropTypes.instanceOf(RegExp),
  data: PropTypes.object,
  dateDisable: PropTypes.bool,
};

export default RegistrationCustom;
