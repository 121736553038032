/* eslint-disable */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, MenuItem, Menu } from '@mui/material';
import { MoreVIcon } from 'tt-ui-kit';
import styles from './Dot3Menu.module.scss';

const options = ['Download'];

const Dot3Menu = ({ params }) => {
  const { menuList, onMenuClick } = params;

  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);

  const handleClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, option) => {
    // setSelectedIndex(index);
    setAnchorEl(null);
    onMenuClick(option);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton className={styles.menuButton} onClick={handleClickButton}>
        <MoreVIcon style={{ width: 20, height: 20, flexShrink: 0, fontSize: 20 }} />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {(menuList ?? options).map((option) => (
          <MenuItem
            key={option}
            // disabled={index === 0}
            // selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

Dot3Menu.propTypes = {
  params: PropTypes.shape({
    menuList: PropTypes.arrayOf(PropTypes.string),
    onMenuClick: PropTypes.func,
  }),
};

export default Dot3Menu;
