import { API_BASE_REPORT_API_URL } from '../../urls';

export const API_CUSTOM_REPORT_URL = `${API_BASE_REPORT_API_URL}/custom-reports/draft`;

export const API_CUSTOM_REPORT_DATA_GET_URL = `${API_CUSTOM_REPORT_URL}/data/get`;
export const API_CUSTOM_REPORT_DATA_SET_URL = `${API_CUSTOM_REPORT_URL}/data/set`;
export const API_CUSTOM_REPORT_DATA_DELETE_URL = `${API_CUSTOM_REPORT_URL}/data/del`;

export const API_CUSTOM_REPORT_QUESTION_NEW_URL = `${API_CUSTOM_REPORT_URL}/question/new`;
export const API_CUSTOM_REPORT_TOPIC_NEW_URL = `${API_CUSTOM_REPORT_URL}/topic/new`;
