import React from 'react';

export const MessagePrintedIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line prettier/prettier
      d="M1.31548 14.4342C1.21143 14.4341 1.10855 14.4122 1.01339 14.3701C0.918224 14.328 0.832864 14.2666 0.762731 14.1897C0.691329 14.1135 0.636846 14.0231 0.602852 13.9243C0.568858 13.8256 0.556123 13.7208 0.565481 13.6167L0.74923 11.5962L9.23773 3.11074L11.8905 5.76274L3.40423 14.2475L1.38373 14.4312C1.36104 14.4333 1.33827 14.4343 1.31548 14.4342ZM12.42 5.23249L9.76798 2.58049L11.3587 0.98974C11.4284 0.920008 11.5111 0.864689 11.6021 0.826946C11.6932 0.789202 11.7908 0.769775 11.8894 0.769775C11.9879 0.769775 12.0855 0.789202 12.1766 0.826946C12.2676 0.864689 12.3503 0.920008 12.42 0.98974L14.0107 2.58049C14.0805 2.65015 14.1358 2.73286 14.1735 2.82391C14.2113 2.91496 14.2307 3.01255 14.2307 3.11112C14.2307 3.20968 14.2113 3.30727 14.1735 3.39832C14.1358 3.48937 14.0805 3.57209 14.0107 3.64174L12.4207 5.23174L12.42 5.23249Z"
      fill="#555555"
    />
  </svg>
);
