import { axiosClient, axiosSSOConfigPrivate } from '../../rest-client';
import {
  API_DRAFTS_NEW_URL,
  API_DRAFTS_REPLACE_URL,
  API_DRAFTS_UPDATE_URL,
  API_DRAFTS_DELETE_URL,
  API_DRAFTS_GET_URL,
  API_DRAFT_GET_BY_ID_URL,
} from './urls';

// export const createDraft = async (params) => axiosClient.post(API_DRAFTS_NEW_URL, params);
const transformDraft = (draft) =>
  Object.keys(draft).reduce(
    (res, key) => ({
      ...res,
      [key]:
        (key === 'periodReportDate' && draft[key]) || key === 'periodReportDateEnd'
          ? new Date(draft[key])
          : draft[key],
    }),
    {}
  );

const transformSingle = ({ data, success }) => ({ data: transformDraft(data), success });

const transformArray = ({ data, success }) => ({
  data: [...data.map((draft) => ({ ...transformDraft(draft) }))],
  success,
});

export const createDraft = async (params) =>
  axiosClient.post(
    API_DRAFTS_NEW_URL,
    params,
    axiosSSOConfigPrivate({}, { shouldTransformResponse: transformSingle })
  );

export const replaceDraft = async (params) =>
  axiosClient.post(
    API_DRAFTS_REPLACE_URL,
    params,
    axiosSSOConfigPrivate({}, { shouldTransformResponse: transformSingle })
  );

export const updateDraft = async (params) =>
  axiosClient.post(
    API_DRAFTS_UPDATE_URL,
    params,
    axiosSSOConfigPrivate({}, { shouldTransformResponse: transformSingle })
  );

export const deleteDraft = async (params) =>
  axiosClient.post(API_DRAFTS_DELETE_URL, params, axiosSSOConfigPrivate());

export const getDraftsWithFilter = async (params) =>
  axiosClient.get(
    API_DRAFTS_GET_URL,
    axiosSSOConfigPrivate({}, { params: params, shouldTransformResponse: transformArray })
  );

export const getDraftById = async (id) =>
  axiosClient.get(
    `${API_DRAFT_GET_BY_ID_URL}/${id}`,
    axiosSSOConfigPrivate({}, { shouldTransformResponse: transformSingle })
  );
